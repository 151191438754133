import httpClient from '@/services/http-client';

export async function changePassword(password, newPassword, repeatNewPassword) {
  const response = await httpClient.put('/api/adm/users/change-password', {
    password,
    newPassword,
    repeatNewPassword,
  });

  return response.data;
}
